// @ts-check
/// <reference lib="dom" />
/// <reference lib="es2020" />
/// <reference types="@rsbuild/core/types" />

import '@elevate-styles/application.css';

/**
 * @typedef {import('@hotwired/stimulus').Application} Application
 */

/**
 * @typedef {import('./stimulus-loader').StimulusLoader } StimulusLoader
 */

const { Application } = await import('@hotwired/stimulus');
const { StimulusLoader } = await import('./stimulus-loader');

if (process.env.NODE_ENV === 'development') {
  console.log('Hello from Elevate ⚡');
}

/** @type {Application} */
const stimulus = Application.start();

/** @type {StimulusLoader} */
const stimulusLoader = new StimulusLoader(stimulus);
stimulusLoader.observe();
